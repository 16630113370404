<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 24 24">
    <path
      d="M10,3.17c0.276,0,0.5-0.224,0.5-0.5V0.594c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5V2.67
      C9.5,2.947,9.724,3.17,10,3.17z"/>
    <path
      d="M4.595,5.964h2.224c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H4.595c-0.276,0-0.5,0.224-0.5,0.5
      S4.318,5.964,4.595,5.964z"/>
    <path
      d="M12.681,5.464c0,0.276,0.224,0.5,0.5,0.5h2.225c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5h-2.225
      C12.904,4.964,12.681,5.188,12.681,5.464z"/>
    <path
      d="M12.304,3.941c0.124,0,0.247-0.045,0.344-0.137l1.812-1.713c0.2-0.189,0.209-0.506,0.02-0.707
      s-0.506-0.209-0.707-0.02L11.96,3.077c-0.2,0.189-0.209,0.506-0.02,0.707C12.039,3.888,12.171,3.941,12.304,3.941z"/>
    <path class="st0" d="M7.353,3.804c0.097,0.091,0.22,0.137,0.344,0.137c0.132,0,0.265-0.052,0.363-0.156
    c0.19-0.201,0.181-0.517-0.02-0.707L6.229,1.365c-0.2-0.189-0.518-0.182-0.707,0.02c-0.19,0.201-0.181,0.517,0.02,0.707
    L7.353,3.804z"/>
    <path
      d="M19,13c-0.366,0-0.705,0.106-1,0.278V13c0-1.103-0.897-2-2-2c-0.414,0-0.8,0.127-1.12,0.344
      C14.607,10.564,13.872,10,13,10c-0.366,0-0.705,0.106-1,0.278V6c0-1.103-0.897-2-2-2S8,4.897,8,6v9.669l-1.961-3.323
      c-0.424-0.669-1.324-0.882-2.09-0.498c-0.654,0.329-1.139,1.12-0.75,2.135c0.078,0.217,1.921,5.345,4.279,8.085
      c0.007,0.006,0.726,0.647,1.114,1.589c0.08,0.193,0.266,0.31,0.462,0.31c0.063,0,0.128-0.012,0.19-0.038
      c0.255-0.104,0.377-0.397,0.272-0.652c-0.47-1.142-1.322-1.909-1.328-1.909c0,0,0,0,0,0.001C6,18.818,4.159,13.699,4.137,13.636
      c-0.213-0.557,0.072-0.799,0.261-0.895c0.265-0.131,0.635-0.116,0.791,0.129l2.88,4.881c0.011,0.018,0.028,0.029,0.04,0.045
      c0.022,0.028,0.043,0.055,0.07,0.078c0.022,0.019,0.045,0.033,0.07,0.047c0.031,0.018,0.062,0.034,0.097,0.046
      c0.028,0.009,0.055,0.013,0.084,0.017C8.454,17.989,8.475,18,8.5,18c0.013,0,0.023-0.006,0.036-0.007
      c0.03-0.002,0.057-0.01,0.087-0.017c0.035-0.009,0.069-0.018,0.1-0.034c0.01-0.005,0.02-0.005,0.03-0.01
      c0.018-0.011,0.029-0.028,0.046-0.041c0.027-0.021,0.053-0.041,0.075-0.067c0.021-0.025,0.037-0.051,0.053-0.079
      c0.016-0.027,0.03-0.054,0.041-0.085c0.011-0.033,0.016-0.066,0.02-0.101C8.991,17.539,9,17.521,9,17.5V6c0-0.551,0.449-1,1-1
      s1,0.449,1,1v6v1.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V12c0-0.551,0.449-1,1-1c0.552,0,1,0.449,1,1v1v1.5
      c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V13c0-0.551,0.448-1,1-1s1,0.449,1,1v2v0.5c0,0.276,0.224,0.5,0.5,0.5
      s0.5-0.224,0.5-0.5V15c0-0.552,0.448-1,1-1c0.623,0,0.69,0.732,0.693,0.994c-0.003,0.026-0.295,2.639-1.29,4.593
      c-0.771,1.515-1.119,2.899-1.274,3.794c-0.048,0.272,0.135,0.531,0.406,0.578c0.029,0.006,0.058,0.008,0.087,0.008
      c0.238,0,0.449-0.171,0.491-0.414c0.145-0.827,0.466-2.108,1.181-3.513c1.08-2.121,1.381-4.824,1.396-4.992
      C20.69,13.803,20.027,13,19,13z"/>
  </svg>
</template>
